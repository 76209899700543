import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {AppRoutes} from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import {NgZorroAntdModule, NZ_DATE_LOCALE, NZ_I18N, NZ_MODAL_CONFIG } from 'ng-zorro-antd';
import {HttpClientModule} from '@angular/common/http';
import {CoreModule} from './core-module/core-module.module';
import {SharedModule} from './shared-module/shared-module.module';
import {CommonUtil} from './shared-module/util/common-util';
import {NotfoundComponent} from './business-module/notfound/notfound.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import {NgxEchartsModule} from 'ngx-echarts';
registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent,
    NotfoundComponent
  ],
  imports: [
    BrowserModule,
    /** 导入 ng-zorro-antd 模块 **/
    NgZorroAntdModule,
    FormsModule,
    NgxEchartsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutes,
    CoreModule,
    SharedModule,
    NzDatePickerModule
  ],
  providers: [
    {provide: NZ_I18N, useValue: CommonUtil.toggleNZi18n()},
    {provide: NZ_MODAL_CONFIG, useValue: {nzMask: true, nzMaskClosable: false}}

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
