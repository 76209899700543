import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {CommonUtil} from '../../util/common-util';
import {ViewerjsService} from '../viewerjs/viewerjs.service';

@Component({
  selector: 'app-kids-image',
  templateUrl: './kids-image.component.html',
  styleUrls: ['./kids-image.component.scss']
})
export class KidsImageComponent implements OnInit {
  @Input() src = '';
  // 图片地址
  uploadUrl = CommonUtil.getUploadUrl();
  // 默认图片地址
  defaultSrc = '/managerPic/u12.png';
  constructor( public $domSan: DomSanitizer,
               public $viewerjsService: ViewerjsService) { }

  ngOnInit() {
  }

  /**
   * 处理外网图片地址
   */
  getSrc(src: string) {
    if (src && src.includes('http')) {
      return src;
    } else {
      return this.$domSan.bypassSecurityTrustUrl(this.uploadUrl + src);
    }
  }
  viewPic() {
    this.$viewerjsService.view([this.getSrc(this.src)]);
  }
}
