import {
  Pipe,
  PipeTransform
} from '@angular/core';
import {BannerTemplateType, BannerType, ColumnTemplateType} from '../enum/template-type.enum';
import {CommonConfig} from '../util/common-config';

@Pipe({name: 'dynamic'})
export class DynamicPipe implements PipeTransform {

  transform(value: string, modifier: string, param: any) {
    if (!modifier) {
      return value;
    }
    return this[modifier](value, param);
  }


  surplusData(value: any): number {
    const nowTime = new Date().getTime();
    if (value > nowTime) {
      const num = value - nowTime;
      return Math.ceil(num / 1000 / 60 / 60 / 24);
    } else {
      return 0;
    }
  }

  remark(value: any, param: any): any {
    if (value && value.includes('\n')) {
      return value.replace(/\n/g, '');
    } else {
      return value;
    }
  }

  toYesNo(value: any, param: any) {
    if (value === 0) {
      return '否';
    } else {
      return '是';
    }
  }

  toAnalysisStatus(value: any, param: any) {
    switch (value) {
      case 1 : return '未解析';
      case 2: return '解析中';
      case 3: return '解析完成';
      case 4: return '解析失败';
    }
  }
  toOperation(value: any, param: any) {
    if (value || value === 0) {
      switch (value) {
        case 0 : return '上下架';
        case 1: return '修改价格';
        case 2: return '修改资源';
      }
    }
  }
  toRefundState(value: any, param: any) {
    if (value || value === 0) {
      switch (value) {
        case 0 : return '待审批';
        case 1: return '同意申请';
        case 2: return '拒绝申请';
        case 3: return '自动退款';
      }
    }

  }
  toCommodityStatus(value: any, param: any) {
    if (value || value === 0) {
      switch (value) {
        case 0 :
          return '全场通用';
        case 1:
          return '电子类通用';
        case 2:
          return '纸质类通用';
        case 3:
          return '指定商品';
      }
    }
  }
  toStatus(value: any, param: any) {
    if (value === 0) {
      return '停用';
    } else {
      return '有效';
    }
  }
  commodityTypePipe(value: any, param: any) {
    if (value === 0) {
      return '电子类';
    } else {
      return '实体类';
    }
  }
  addDaySuffix(value: any, param: any) {
    if (value) {
      return value + '天';
    }
  }
  couponTypePipe(value: any, param: any) {
    if (value === 1) {
      return '电子书优惠券';
    } else {
      return '实体书优惠券';
    }
  }
  integralTypePipe(value: any, param: any) {
    if (value === 1) {
      return '电子书';
    } else {
      return '优惠券';
    }
  }
  /**
   * 活动类型转换
   * param value
   */
  toSpecialTopicType(value: any) {
    const specialTopicTypeList = CommonConfig.getSpecialTopicType();
    const specialTopicType = specialTopicTypeList.filter(item => item.value === value);
    if (specialTopicType && specialTopicType.length > 0) {
      return specialTopicType[0]['label'];
    } else {
      return '--';
    }
  }
  toBannerTemplateType(value: any, param: any) {
    const bannerTemplateTypeList = CommonConfig.getBannerTemplateType();
    const bannerTemplateTypeItem = bannerTemplateTypeList.filter(item => item.value === value);
    if (bannerTemplateTypeItem && bannerTemplateTypeItem.length > 0) {
      return bannerTemplateTypeItem[0]['label'];
    } else {
      return '--';
    }
  }
  toColumnTemplateType(value: any) {
    const columnTemplateTypeList = CommonConfig.getColumnTemplateType();
    const columnTemplateTypeItem = columnTemplateTypeList.filter(item => item.value === value);
    if (columnTemplateTypeItem && columnTemplateTypeItem.length > 0) {
      return columnTemplateTypeItem[0]['label'];
    } else {
      return '--';
    }
  }

  toColumnGroupTemplateType(value: any, param: any) {
    const columnGroupList: Array<any> = JSON.parse(sessionStorage.getItem('groupStyle'));
    if (columnGroupList) {
      const group = columnGroupList.find(item => item.groupStyleCode === value);
      return group.groupStyleName;
    } else {
      return null;
    }
  }
  toNull(value: any, param: any) {
    if (value) {
      return value;
    } else {
      return '--';
    }
  }

  /**
   * banner和栏目模板转化
   */
  pictureBook(value: any) {
      const banner = CommonConfig.getBannerType().filter(item => item.value === value);
      return banner[0].label;
  }
  /**
   *  dateNum 为时间戳  type 根据自己的需求填写
   */
  formatTime(dateNum: number, type: number) {
    if (dateNum) {
      /*
  1 2017/04/19 18:00:00
  2 2017年04月19日 18:00:00
  3 2017/04/19 18时
  4 2017/04/19
  5 2017-04-19 18:10:10
  6 2017-04-19 18:10:10 星期三
  7 2017-04-19T18:10
  8 2017-04-19 18:10:10 (星期三)
*/
      const date = new Date(dateNum);

      const year = date.getFullYear();
      let month = (date.getMonth() + 1).toString();
      if (month.length === 1) {
        month = 0 + month;
      }
      let day = date.getDate().toString();
      if (day.length === 1) {
        day = 0 + day;
      }
      // let hour = date.getHours();
      let hour = date.getHours().toString();
      if (hour.length === 1) {
        hour = 0 + hour;
      }
      let minutes = date.getMinutes().toString();
      if (minutes.length === 1) {
        minutes = 0 + minutes;
      }
      let seconds = date.getSeconds().toString();
      if (seconds.length === 1) {
        seconds = 0 + seconds;
      }
      const week = {
        0: '星期天',
        1: '星期一',
        2: '星期二',
        3: '星期三',
        4: '星期四',
        5: '星期五',
        6: '星期六',
      };
      const todayWeek = week[date.getDay()];

      const timeMap = {
        1: `${year}/${month}/${day} ${hour}:${minutes}:${seconds}`,
        2: `${year}年${month}月${day}日 ${hour}:${minutes}:${seconds}`,
        3: `${year}-${month}-${day} ${hour}时`,
        4: `${year}/${month}/${day}`,
        5: `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`,
        6: `${year}-${month}-${day} ${hour}:${minutes}:${seconds} ${todayWeek}`,
        7: `${year}-${month}-${day}T${hour}:${minutes}`,
        8: `${year}-${month}-${day} ${hour}:${minutes}:${seconds} (${todayWeek})`,
      };
      return timeMap[type];
    } else {
      return '--';
    }
  }
}
