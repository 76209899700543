const RESOURCE = '/ksr_manager';

export const commodityApiUrl = {
  // 商品管理列表
  queryCommodityList: `${RESOURCE}/commodity/queryCommodityList`,
  // 添加商品
  addCommodityInfo: `${RESOURCE}/commodity/addCommodityInfo`,
  // 商品详情
  queryCommodityInfo: `${RESOURCE}/commodity/queryCommodityInfo`,
  // 下架
  lowerShelfCommodity: `${RESOURCE}/commodity/lowerShelfCommodity`,
  // 上架
  upperShelfCommodity: `${RESOURCE}/commodity/upperShelfCommodity`,
  // 删除
  deleteCommodity: `${RESOURCE}/commodity/deleteCommodity`,
  // 查询操作信息
  queryOperationList: `${RESOURCE}/commodity/queryOperationList`,
  // 修改商品信息
  modifyCommodityInfo: `${RESOURCE}/commodity/modifyCommodityInfo`,
};
export const couponApiUrl = {
  // 查询优惠券列表
  queryCouponList: `${RESOURCE}/coupon/queryCouponList`,
  // 删除优惠券
  deleteCoupon: `${RESOURCE}/coupon/deleteCoupon`,
  // 新增优惠券
  insertCoupon: `${RESOURCE}/coupon/insertCoupon`,
  // 修改优惠券
  updateCoupon: `${RESOURCE}/coupon/updateCoupon`,
  // 查询详情
  queryCouponInfo: `${RESOURCE}/coupon/queryCouponInfo`,
};

export const integralApiUrl = {
  // 查询积分兑换列表
  queryIntegralExchangeInfoList: `${RESOURCE}/integralExchange/queryIntegralExchangeInfoList`,
  // 上架
  publishIntegralExchange: `${RESOURCE}/integralExchange/publishIntegralExchange`,
  // 下架
  repealIntegralExchange: `${RESOURCE}/integralExchange/repealIntegralExchange`,
  // 新增积分兑换
  insertIntegralExchange: `${RESOURCE}/integralExchange/insertIntegralExchange`,
  // 查询详情
  queryIntegralExchangeInfoById: `${RESOURCE}/integralExchange/queryIntegralExchangeInfoById`,
  // 修改积分兑换
  updateIntegralExchange: `${RESOURCE}/integralExchange/updateIntegralExchange`,
  // 查询明细
  queryExchangeFlowList: `${RESOURCE}/integralExchange/queryExchangeFlowList`,
};
export const vipTypeApiUrl = {
  // 查询vip
  queryVipTypeInfoById: `${RESOURCE}/vipType/queryVipTypeInfoById`,
  // 新增vip类型
  insertVipType: `${RESOURCE}/vipType/insertVipType`,
  // 修改
  updateVipTypeInfo: `${RESOURCE}/vipType/updateVipTypeInfo`,
  // 删除
  deleteVipType: `${RESOURCE}/vipType/deleteVipType`,
  // 查询vip类型集合
  queryVipTypeList: `${RESOURCE}/vipType/queryVipTypeList`,
  // 查询vip用户集合
  queryVipUserList: `${RESOURCE}/vipType/queryVipUserList`,
  // 充值明细
  queryVipRechargeFlowList: `${RESOURCE}/vipType/queryVipRechargeFlowList`,
};

export const preferentialActivityApiUrl = {
  // 查询优惠活动列表
  queryDiscountsActivityList: `${RESOURCE}/discountsActivity/queryDiscountsActivityList`,
  // 批量删除优惠活动
  batchDeleteDiscountsActivity: `${RESOURCE}/discountsActivity/batchDeleteDiscountsActivity`,
  // 批量上架优惠活动
  batchPublishDiscountsActivity: `${RESOURCE}/discountsActivity/batchPublishDiscountsActivity`,
  // 批量下架优惠活动
  batchRepealDiscountsActivity: `${RESOURCE}/discountsActivity/batchRepealDiscountsActivity`,
  // 上下架切换
  discountsActivityStateSwitch: `${RESOURCE}/discountsActivity/discountsActivityStateSwitch`,
  // 添加优惠活动
  addDiscountsActivity: `${RESOURCE}/discountsActivity/addDiscountsActivity`,
  // 修改
  modifyDiscountsActivity: `${RESOURCE}/discountsActivity/modifyDiscountsActivity`,
  // 查详情
  queryDiscountsActivityById: `${RESOURCE}/discountsActivity/queryDiscountsActivityById`,
};
export const orderApiUrl = {
  // 查询退款列表
  queryRefundList: `${RESOURCE}/refund/queryRefundList`,
  // 查询退款详情
  queryRefundInfo: `${RESOURCE}/refund/queryRefundInfo`,
  // 申请
  approval: `${RESOURCE}/refund/approval`,
  // 实体类订单列表
  queryFictitiousOrderList: `${RESOURCE}/order/queryFictitiousOrderList`,
  // 电子类订单列表
  queryEntityOrderList: `${RESOURCE}/order/queryEntityOrderList`,
  // 填写订单
  fillInLogisticsInfo: `${RESOURCE}/order/fillInLogisticsInfo`,
  // 查询订单详情
  queryEntityOrderInfo: `${RESOURCE}/order/queryEntityOrderInfo`,
};
