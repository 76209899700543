import {Injectable} from '@angular/core';
import {RuleUtil} from '../../util/rule-util';
import {ColumnConfig} from '../../entity/tableConfig';
import {NzI18nService} from 'ng-zorro-antd';
import {CommonConfig} from '../../util/common-config';

@Injectable()
export class CommodityColumnConfigService {

  constructor(
    public $ruleUtil: RuleUtil,
    public $nzI18n: NzI18nService
  ) { }

  /**
   * 商品清单列表配置
   */
  private select = {
    type: 'select', fixedStyle: { fixedLeft: true, style: { left: '0px' } }, width: 42
  };
  private serialNumberSelect = {
    type: 'serial-number', width: 70, title: '序号',
    fixedStyle: { fixedRight: true, style: { left: '0px' } }
  };
  /**
   * 获取资源清单列表配置
   */
  getTableResourceListColumnConfig(commodityNameTemp): Array<ColumnConfig> {
    const tableConfig: Array<ColumnConfig> = [
      this.select,
      {
        title: '商品名称', key: 'commodityName', width: 220, isShowSort: true,
        type: 'render',
        renderTemplate: commodityNameTemp,
        searchable: true, searchConfig: { type: 'input' },
      },
      {
        title: '商品类型', key: 'commodityType', width: 180, isShowSort: true,
        searchable: true,
        pipe: 'commodityTypePipe',
        searchConfig: { type: 'input' },
      },
      {
        title: '原价(￥)', key: 'price', width: 150, isShowSort: true,
        searchable: true,
        searchConfig: { type: 'number' },
      },
    ];
    return tableConfig;
  }
  /**
   * 获取资源清单列表配置
   */
  getTableResourceListSelectColumnConfig(): Array<ColumnConfig> {
    const tableConfigSelect: Array<ColumnConfig> = [
      this.serialNumberSelect,
      {
        title: '资源名称', key: 'commodityName',
        searchable: true, searchConfig: { type: 'input' },
      },
      {
        title: '操作', searchable: true,
        searchConfig: { type: 'operate' }, key: '',
        width: 80, fixedStyle: { fixedRight: true, style: { right: '0px' } }
      }
    ];
    return tableConfigSelect;
  }
}
