import {AfterViewInit, Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import * as _ from 'lodash';

@Directive({
  selector: '[permissionKey]'
})
export class PermissionDirective implements AfterViewInit{

  @Input('permissionKey')
  set condition(key: number) {
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    if (loginInfo) {
      const codeList = loginInfo.operations;
      if (_.includes(codeList, key)) { // 创建模板对应的内嵌视图
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    }
  }

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }
  ngAfterViewInit(): void {
  }
}
