import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {AccessGuardService} from './router-guard/access-guard.service';
import { ColumnManageService } from './api-service/column-manage/column-manage.service';


const SERVICE_PROVIDERS = [
  ColumnManageService
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  exports: [ReactiveFormsModule],
  providers: [AccessGuardService, SERVICE_PROVIDERS],
})
export class CoreModule {
}
