import {Component, EventEmitter, OnInit, Output, OnChanges, SimpleChanges, } from '@angular/core';
import * as Slider from 'swiper/dist/js/swiper.js';
@Component({
  selector: 'app-picture-exhibition',
  templateUrl: './picture-exhibition.component.html',
  styleUrls: ['./picture-exhibition.component.scss']
})

export class PictureExhibitionComponent implements OnInit, OnChanges {

  constructor() { }
  @Output()
  close = new EventEmitter();
  imgSrc = '';
  mySlider;
  pageLoading = false;
  isHidden = true;
  sliderConfig = [];

  pictureExhibitionInto(src) {
    this.imgSrc = src[0];
    this.sliderConfig = src;
    this.pageLoading = true;
    if (this.mySlider) {
      this.mySlider.destroy(true, true);
      this.mySlider = null;
    }
    setTimeout(() => {
      this.mySlider = new Slider('.swiper-container', {
        direction: 'horizontal', // 垂直切换选项
        loop: false, // 循环模式选项
        slidesPerView: 1,
        // effect : 'coverflow',
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          disabledClass: 'my-button-disabled',
          hideOnClick: true,
        },
        // // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          // dynamicBullets: true,
          // dynamicMainBullets: 3
          clickableClass : 'my-pagination-clickable',
          type: 'fraction',
         /* formatFractionCurrent: function (number) {
          },*/
        },
        grabCursor: true,
        // 被选中的滑块居中，默认居左
        centeredSlides: false,
        // slidesOffsetBefore: 250,
        // slidesOffsetAfter: 50,
        centerInsufficientSlides: true,
        auto: true,
        // autoplay: true,
        spaceBetween : 100,
        speed: 1000,
      });
      this.pageLoading = false;
    }, 1000);
  }
  closes() {
    this.close.emit(true);
  }
  ngOnChanges(changes: SimpleChanges): void {

  }
  mouseover() {
    this.isHidden = false;
  }

  mouseout() {
    this.isHidden = true;
  }
  clickSlide(slide) {
    // this.selectChange.emit(slide);
  }
  ngOnInit() {
  }

}
