export enum ResourceType {
  EBOOK = 1,
  VOICEBOOK = 2,
  HELPBOOK = 3,
  VIDEOBOOK = 4,
  PICBOOK = 5
}

export enum DiversionType {
  /**
   * 好友分享
   */
  FRIENDSSHARE = 1,

  /**
   * 公众号引入
   */
  PUBLICNUMBERINTRODUCTION = 2,

  /**
   * 线下引入
   */
  OFFLINEINTRODUCTION = 3,

  /**
   * 一般网站引入
   */
  WEBSITEINTRODUCTION = 4,

  /**
   * 日新学堂
   */
  NISSINSCHOOL = 5,

  /**
   * 指尖长江
   */
  FINGERTIPYANGTZERIVER = 6,

  /**
   * 今日头条
   */
  HEADTODAY = 7,

  /**
   * 书香荆楚
   */
  CHUCHUJINGCHU = 8,

  /**
   * 中文网
   */
  CHINESENET = 9,

  /**
   * 数字公司大屏
   */
  LARGESCREENDIGITALCOMPANY = 10,

  /**
   * 综合运营平台
   */
  INTEGRATEDOPERATIONPLATFORM = 12
}
