/**
 * Created by xiaoconghu on 2019/1/14.
 */
import {NzI18nService} from 'ng-zorro-antd';

/**
 * 显示类型
 */
export const showTypeCode = {
  '横向轮播' : 0,
  '图文列表' : 1,
  '开学第一课': 2
  // 0: '横向轮播',
  // 1: '图文列表'
};

/**
 * 资源类型
 */
export const resourceType = {
  '电子书': 1 ,
  '有声书': 2 ,
  '导读手册': 3 ,
  '视频课程': 4,
  '绘本': 5
};

export function getShwoStyle(i18n: NzI18nService, code = null): any {
  return codeTranslate(showTypeCode, i18n, code);
}
export function getResourceType(i18n: NzI18nService, code = null): any {
  return codeTranslate(resourceType, i18n, code);
}

/**
 * 枚举翻译
 * param codeEnum
 * param {NzI18nService} i18n
 * param {any} code
 * returns {any}
 */
export function codeTranslate(codeEnum, i18n: NzI18nService, code = null) {
  if (code !== null) {
    for (const i of Object.keys(codeEnum)) {
      if (codeEnum[i] === code) {
        return i18n.translate(`${i}`);
      }
    }
  } else {
    return Object.keys(codeEnum)
      .map(key => ({label: i18n.translate(`${key}`), code: codeEnum[key]}));
  }
}
