const MANAGE =  '/ksr_manager';

export const  rewardActivityApi = {
  // 查询树列表
  queryModuleTree: `${MANAGE}/prize/queryModuleTree`,
  queryPrizeActivityList: `${MANAGE}/prize/queryPrizeActivityList`,
  addPrizeActivityInfo: `${MANAGE}/prize/addPrizeActivityInfo`,
  prizeDraw: `${MANAGE}/prize/prizeDraw`,
  queryWinnerList: `${MANAGE}/winner/queryWinnerList`,
  modifyPrizeInfo: `${MANAGE}/winner/modifyPrizeInfo`,
  modifyDeliverPrize: `${MANAGE}/winner/modifyDeliverPrize`,
};

export const  lotteryActivityApi = {
  // 新增抽奖
  insertLottery: `${MANAGE}/luckDraw/insert`,
  queryLottery: `${MANAGE}/luckDraw/queryPage`,
  upperShelf: `${MANAGE}/luckDraw/upperShelf`,
  lowerShelf: `${MANAGE}/luckDraw/lowerShelf`,
  deleteLottery: `${MANAGE}/luckDraw/delete`,
  lotteryDetails: `${MANAGE}/luckDraw/details`,
  winnerPages: `${MANAGE}/luckDraw/winnersPage`,
};
