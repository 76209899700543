import {ResourceType, DiversionType} from '../enum/resource-type.enum';
import {BannerTemplateType, BannerType, ColumnTemplateType} from '../enum/template-type.enum';
import {SpecialTopicType} from '../enum/special-topic-type.enum';

export class CommonConfig {
  static getResourceTypeList() {
    const list = [
      {
        label: '电子书',
        value: ResourceType.EBOOK
      },
      {
        label: '有声书',
        value: ResourceType.VOICEBOOK
      },
      {
        label: '导读手册',
        value: ResourceType.HELPBOOK
      },
      {
        label: '视频课程',
        value: ResourceType.VIDEOBOOK
      },
      {
        label: '绘本',
        value: ResourceType.PICBOOK
      }
    ];
    return list;
  }

  static getResourceType(data) {
    switch (data) {
      case ResourceType.EBOOK :
        return '电子书';
      case ResourceType.VOICEBOOK :
        return '有声书';
      case ResourceType.HELPBOOK :
        return '导读手册';
      case ResourceType.VIDEOBOOK :
        return '视频课程';
      case ResourceType.PICBOOK :
        return '绘本';
    }
  }

  static getSourceChannelType(data) {
    switch (data) {
      case ResourceType.EBOOK :
        return '好友分享';
      case ResourceType.VOICEBOOK :
        return '公众号引入';
      case ResourceType.HELPBOOK :
        return '线下引入';
      case ResourceType.VIDEOBOOK :
        return '一般网站引入';
    }
  }

  static getDiversionType(data) {
    switch (data) {
      case DiversionType.FRIENDSSHARE :
        return '好友分享';
      case DiversionType.PUBLICNUMBERINTRODUCTION :
        return '公众号引入';
      case DiversionType.OFFLINEINTRODUCTION :
        return '线下引入';
      case DiversionType.WEBSITEINTRODUCTION :
        return '一般网站引入';
      case DiversionType.NISSINSCHOOL :
        return '日新学堂';
      case DiversionType.FINGERTIPYANGTZERIVER :
        return '指尖长江';
      case DiversionType.HEADTODAY :
        return '今日头条';
      case DiversionType.CHUCHUJINGCHU :
        return '书香荆楚';
      case DiversionType.CHINESENET :
        return '中文网';
      case DiversionType.LARGESCREENDIGITALCOMPANY :
        return '数字公司大屏';
      case DiversionType.INTEGRATEDOPERATIONPLATFORM :
        return '综合运营平台';
    }
  }

  /**
   * 获取分级数据
   */
  static getGradeList() {
    if (localStorage.getItem('gradeList')) {
      return JSON.parse(localStorage.getItem('gradeList'));
    } else {
      return [];
    }
  }

  /**
   * 获取测评标签
   */
  static getEvaluationLabelsList() {
    if (localStorage.getItem('evaluationLabels')) {
      const evaluationLabelsList = JSON.parse(localStorage.getItem('evaluationLabels'));
      return evaluationLabelsList.map(item => {
        return {
          label: item.evaluationLabelName,
          value: item.evaluationLabelCode
        };
      });
    } else {
      return [];
    }
  }

  /**
   * banner 类型
   */
  static getBannerType() {
    const list = [
      {
        label: '首页',
        value: BannerType.INDEX
      },
      {
        label: '绘本专栏',
        value: BannerType.PICTUREBOOK
      },
    ];
    return list;
  }

  /**
   * banner模板类型
   */
  static getBannerTemplateType() {
    const list = [
      {
        label: '详情页',
        value: BannerTemplateType.INFO
      },
      {
        label: '列表页',
        value: BannerTemplateType.LIST
      },
      {
        label: '自定义页',
        value: BannerTemplateType.URL
      },
      {
        label: '站内跳转',
        value: BannerTemplateType.INSTATION
      },
      {
        label: '小程序',
        value: BannerTemplateType.MICRO002
      }
    ];
    return list;
  }

  /**
   * banner模板类型
   */
  static getColumnTemplateType() {
    const list = [
      {
        label: '横向轮播',
        value: ColumnTemplateType.AXIS
      },
      {
        label: '图文列表',
        value: ColumnTemplateType.LIST
      },
      {
        label: '自定义页',
        value: ColumnTemplateType.URL
      }, {
        label: '站内跳转',
        value: ColumnTemplateType.INSTATION
      }, {
        label: '作家专栏',
        value: ColumnTemplateType.AUTHOR
      }
    ];
    return list;
  }

  static getSpecialTopicType() {
    const list = [
      {
        label: '线上',
        value: SpecialTopicType.ONLINE
      },
      {
        label: '线下',
        value: SpecialTopicType.OFFLINE
      },
      {
        label: '直播',
        value: SpecialTopicType.LINE
      },
      {
        label: '报名',
        value: SpecialTopicType.APPLY
      },
      {
        label: '聊天室',
        value: SpecialTopicType.CHAT
      }
    ];
    return list;
  }

  static transformGrade(value) {
    const gradeList = CommonConfig.getGradeList();
    const item = gradeList.filter(i => i.value === parseInt(value, 0));
    if (item && item.length > 0) {
      return item[0].label;
    } else {
      return '--';
    }
  }

  static convertKey(arr, key) {
    const newArr = [];
    arr.forEach((item, index) => {
      const newObj = {};
      for (let i = 0; i < key.length; i++) {
        newObj[key[i]] = item[Object.keys(item)[i]];
      }
      newArr.push(newObj);
    });
    return newArr;
  }

  /**
   * 获取Echart颜色配置
   */
  static getChartColorConfig() {
    return ['#37a2da', '#32c5e9', '#67e0e3', '#9fe6b8', '#ffdb5c', '#ff9f7f', '#fb7293', '#e690d1', '#9d96f5', '#fb7293', '#e062ae'];
  }
}
