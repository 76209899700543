export default {
  sure: '确定',
  cancel: '取消',
  delete: '删除',
  sureDeleteRoleTitle: '确定删除该角色？',
  sureDeleteUserTitle: '确定删除该用户？',
  sureDeleteRoleContent: '角色删除后，将不可恢复，请谨慎操作',
  sureDeleteUserContent: '用户删除后，将不可恢复，请谨慎操作',
  add: '新增',
  edit: '编辑',
  permissionConfig: '配置权限',
  rolePermission: '角色权限配置',
  roleName: '角色名称',
  editRole: '修改角色',
  addRole: '新增角色',
  addUser: '新增角色',
  editUser: '新增角色',
  description: '描述',
  descriptionText: '请输入角色描述',
  serialNumber: '序号',
  operate: '操作',
  userDetail: '用户详情',
  disValid: '停用',
  valid: '有效',
};
