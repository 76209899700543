const RESOURCE = '/ksr_manager';
const QR = '/ksr_qrcode';

export const  audioApiUrl = {
  // 获取页面上传ID
  getUploadId: `${RESOURCE}/fileUpload/getUploadId`,
  // 获取资源分级下拉框数据
  queryAllGrade: `${RESOURCE}/grade/queryAllGrade`,
  // 上传文件
  upload: `${RESOURCE}/fileUpload/upload`,
  // 资源上下移
  exchangeContentIndex: `${RESOURCE}/resourceManager/exchangeContentIndex`,
  // 查询有声书文件列表
  queryResourceContent: `${RESOURCE}/resourceManager/queryResourceContent`,
  //  查询有声书列表
  queryAudioBookList: `${RESOURCE}/audioBook/queryAudioBookList`,
  // 查询有声书详情
  queryAudioBookInfo: `${RESOURCE}/audioBook/queryAudioBookInfo`,
  // 添加有声书
  addAudioBook: `${RESOURCE}/audioBook/addAudioBook`,
  // 修改有声书
  updateAudioBook: `${RESOURCE}/audioBook/updateAudioBook`,
  // 上架有声书
  publishAudioBook: `${RESOURCE}/audioBook/publishAudioBook`,
  // 热门开关
  isHotSwitch: `${RESOURCE}/resourceManager/isHotSwitch`,
  // 推荐开关
  isRecommendSwitch: `${RESOURCE}/resourceManager/isRecommendSwitch`,
  // 批量上架有声书
  batchPublishAudioBook: `${RESOURCE}/audioBook/batchPublishAudioBook`,
  // 下架有声书
  repealAudioBook: `${RESOURCE}/audioBook/repealAudioBook`,
  // 批量下架有声书
  batchRepealAudioBook: `${RESOURCE}/audioBook/batchRepealAudioBook`,
  // 删除有声书或电子书
  deleteResource: `${RESOURCE}/resourceManager/deleteResource`,
  // 批量删除有声书或电子书
  deleteResourceBatch: `${RESOURCE}/resourceManager/deleteResourceBatch`,
  // 查詢上下架记录
  queryResourceOperationList: `${RESOURCE}/resourceManager/queryResourceOperationList`,
  // 查询资源分类
  queryAllClassify: `${RESOURCE}/category/queryAllClassify`,
  // 更改上传文件名
  updateResourceContent: `${RESOURCE}/resourceManager/updateResourceContent`
};
export const  eBookApiUrl = {
  // 查询电子书列表
  queryEBookList: `${RESOURCE}/ebook/queryEBookList`,
  // 查询电子书详情
  queryEBookInfo: `${RESOURCE}/ebook/queryEBookInfo`,
  // 添加电子书
  addEBook: `${RESOURCE}/ebook/addEBook`,
  // 修改电子书
  updateEBook: `${RESOURCE}/ebook/updateEBook`,
  // 上架电子书
  publishEBook: `${RESOURCE}/ebook/publishEBook`,
  // 批量上架电子书
  batchPublishEBook: `${RESOURCE}/ebook/batchPublishEBook`,
  // 下架电子书
  repealEBook: `${RESOURCE}/ebook/repealEBook`,
  // 批量下架电子书
  batchRepealEBook: `${RESOURCE}/ebook/batchRepealEBook`,
  // 修改电子书文件
  addResourceFile: `${RESOURCE}/resourceManager/addResourceFile`,
  // 手动解析
  parsingResource: `${RESOURCE}/resourceManager/parsingResource`,
  // 修改时删除电子书文件
  deleteResourceFileRelation: `${RESOURCE}/resourceManager/deleteResourceFileRelation`,
  // 添加扫描图片
  addHdPicture: `${RESOURCE}/resourceManager/addHdPicture`
};
export const  qrApiUrl = {
  // 查询目录树结构
  queryCatalogList: `${QR}/catalog/queryCatalogList` ,
  // 添加目录信息
  addCatalogInfo: `${QR}/catalog/addCatalogInfo` ,
  // deleteCatalogInfo
  deleteCatalogInfo: `${QR}/catalog/deleteCatalogInfo` ,
  // 修改目录信息
  modifyCatalogInfo: `${QR}/catalog/modifyCatalogInfo` ,
  // 返回二维码
  generateQrCode: `${QR}/qrCode/generateQrCode` ,
  // 添加二维码
  addQrCodeInfo: `${QR}/qrCode/addQrCodeInfo` ,
  // 删除二维码
  deleteQrCodeInfo: `${QR}/qrCode/deleteQrCodeInfo` ,
  // 发布二维码
  publishQrCodeInfo: `${QR}/qrCode/modifyQrCodeStatePublish` ,
  // 取消发布二维码
  unPublishQrCodeInfo: `${QR}/qrCode/modifyQrCodeStateUnpublished` ,
  // 查询二维码列表
  queryQrCodeList: `${QR}/qrCode/queryQrCodeList` ,
  // 关联资源
  relateResource: `${QR}/qrCode/relateResource` ,
  // 查询资源详情
  queryQrCodeResourceInfo: `${QR}/qrCode/queryQrCodeResourceInfo` ,
  addPublishedQrCode: `${QR}/qrCode/addPublishedQrCode` ,
};
export const videoApiUrl = {
  // 查询微课视频列表
  queryVideoCourseList: `${RESOURCE}/videoCourse/queryVideoCourseList`,
  // 查询详情
  queryVideoCourseInfo: `${RESOURCE}/videoCourse/queryVideoCourseInfo`,
  // 添加微课视频
  addVideoCourse: `${RESOURCE}/videoCourse/addVideoCourse`,
  // 修改微课视频
  updateVideoCourse: `${RESOURCE}/videoCourse/updateVideoCourse`,
  // 上架微课视频
  publishVideoCourse: `${RESOURCE}/videoCourse/publishVideoCourse`,
  // 批量上架
  batchPublishVideoCourse: `${RESOURCE}/videoCourse/batchPublishVideoCourse`,
  // 下架微课视频
  repealVideoCourse: `${RESOURCE}/videoCourse/repealVideoCourse`,
  // 批量下架
  batchRepealVideoCourse: `${RESOURCE}/videoCourse/batchRepealVideoCourse`,
};

// 导读手册
export const helpReadResource = {
  getUploadId: `${RESOURCE}/fileUpload/getUploadId`,
  // 歌词解析
  getLyricAnalysis: `${RESOURCE}/guideBook/lyricAnalysis`,
  // 文件上传
  upload: `${RESOURCE}/fileUpload/upload`,
  // 新增
  insertGuideBook: `${RESOURCE}/guideBook/insertGuideBook`,
  // 列表查询
  queryGuideBookPageList: `${RESOURCE}/guideBook/queryGuideBookPageList`,
  // 是否推荐
  isRecommendSwitch: `${RESOURCE}/guideBook/isRecommendSwitch/`,
  // 是否热门
  isHotSwitch: `${RESOURCE}/guideBook/isHotSwitch/`,
  // 上架
  publishGuideBook: `${RESOURCE}/guideBook/publishGuideBook`,
  // 下架
  batchRepealGuideBook: `${RESOURCE}/guideBook/batchRepealGuideBook`,
  // 删除
  deleteGuideBook: `${RESOURCE}/guideBook/deleteGuideBook`,
  // 根据id获取详情信息
  queryGuideInfoById: `${RESOURCE}/guideBook/queryGuideInfoById/`,
  // 导读手册修改
  updateGuideBook: `${RESOURCE}/guideBook/updateGuideBook`,
  // 导读手册预览
  generateQrCodeByResourceId : `${QR}/qrCode/generateQrCodeByResourceId/`
};
// 绘本管理
export const pictureBookResource = {
  // 查询绘本列表
  queryPicBookList: `${RESOURCE}/picBook/queryPicBookList`,
  // 上架
  putOnShelf: `${RESOURCE}/picBook/putOnShelf`,
  // 下架
  pullOffShelf: `${RESOURCE}/picBook/pullOffShelf`,
  // 热门开关
  isHotSwitch: `${RESOURCE}/picBook/isHotSwitch`,
  // 推荐开关
  isRecommendSwitch: `${RESOURCE}/picBook/isRecommendSwitch`,
  // 查询资源详情
  queryPickBook: `${RESOURCE}/picBook/queryPickBook`,
  // 添加绘本资源
  addPicBook: `${RESOURCE}/picBook/addPicBook`,
  // 修改绘本资源
  modifyPicBook: `${RESOURCE}/picBook/modifyPicBook`,
  queryAllAgeRangeList: `${RESOURCE}/resourceManager/queryAllAgeRangeList`,
};


// 资源分组
export const resourceGroup = {
  // 资源分组列表查询
  queryResourceGroupList: `${RESOURCE}/resourceGroup/queryResourceGroupList`,
  // 新增
  insertResourceGroup: `${RESOURCE}/resourceGroup/insertResourceGroup`,
  // 修改资源分组信息
  updateResourceGroup: `${RESOURCE}/resourceGroup/updateResourceGroup`,
  // 查询分组详情
  queryResourceGroupInfoById: `${RESOURCE}/resourceGroup/queryResourceGroupInfoById`,
  // 删除资源分组
  deleteResourceGroup: `${RESOURCE}/resourceGroup/deleteResourceGroup`,
  // 隐藏资源分组
  repealResourceGroupDetail: `${RESOURCE}/resourceGroup/repealResourceGroupDetail`,
  // 显示资源分组
  publishResourceGroupDetail: `${RESOURCE}/resourceGroup/publishResourceGroupDetail`,
};

export const resourceCommonApi = {
  getCode: `${RESOURCE}/picBook/getCode`
}
