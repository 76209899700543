import {en_US, zh_CN} from 'ng-zorro-antd';
import _zh_CN from '../../../assets/i18n/zh_CN';
import _en_US from '../../../assets/i18n/en_US';
import {SessionUtil} from './session-util';
import {CommonAesService} from './common-aes.service';

/**
 * Created by xiaoconghu on 2018/11/20.
 */
export class CommonUtil {
  /**
   * 获取uuid
   * param {number} len
   * returns {string}
   */
  public static getUUid(len: number = 36) {
    const uuid = [];
    const str = '0123456789abcdef';
    for (let i = 0; i < len; i++) {
      uuid[i] = str.substr(Math.floor(Math.random() * 0x10), 1);
    }
    if (len === 36) {
      uuid[14] = '4';
      uuid[19] = str.substr((uuid[19] & 0x3 | 0x8), 1);
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    }
    return uuid.join('').replace(/-/g, '');
  }

  /**
   * 切换语言包
   * param localId
   * returns {any}
   */
  public static toggleNZi18n(_localId?) {
    const localId = _localId || window.localStorage.getItem('localId') || 'zh_CN';
    let language;
    switch (localId) {
      case 'zh_CN':
        language = Object.assign(zh_CN, _zh_CN);
        if (_localId) {
          window.localStorage.setItem('localId', 'zh_CN');
        }
        break;
      case 'en_US':
        language = Object.assign(en_US, _en_US);
        if (_localId) {
          window.localStorage.setItem('localId', 'en_US');
        }
        break;
      default:
        language = Object.assign(zh_CN, _zh_CN);
        break;
    }
    return language;
  }

  /**
   * 时间格式化
   * param fmt
   * param date
   * returns {any}
   */
  public static dateFmt(fmt, date) {
    const o = {
      'M+': date.getMonth() + 1,                 // 月份
      'd+': date.getDate(),                    // 日
      'h+': date.getHours(),                   // 小时
      'm+': date.getMinutes(),                 // 分
      's+': date.getSeconds(),                 // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      'S': date.getMilliseconds()             // 毫秒
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {

        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
      }
    }
    return fmt;
  }

  /**
   * 深拷贝
   * param obj   拷贝对象
   * returns {any[] | {}}   返回拷贝对象
   */
  public static deepClone(obj): any {
    const objClone = Array.isArray(obj) ? [] : {};
    if (obj && typeof obj === 'object') {
      for (const key in obj) {
        // 判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = this.deepClone(obj[key]);
        } else {
          // 如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
    return objClone;
  }

  /**
   * 返回几天前日期
   * param day
   */
  public static funDate(day: number) {
    const date1 = new Date();
    const time1 = date1.getFullYear() + '/' + (date1.getMonth() + 1) + '/' + date1.getDate();
    const date2 = new Date(time1);
    date2.setDate(date1.getDate() + day);
    return date2.getFullYear() + '/' + (date2.getMonth() + 1) + '/' + date2.getDate();
  }

  /**
   * web端导出html文件公共方法
   * param name 导出的文件名
   */
  public static exportHtml(name) {
    // 取html内容
    const test = document.getElementsByTagName('html')[0].outerHTML;
    const urlObject = window.URL || window['webkitURL'] || window;
    const export_blob = new Blob([test]);
    // 动态创建a标签
    const a = document.createElement('a');
    document.body.appendChild(a);
    // url地址为命名空间http://www.w3.org/1999/xhtml 或 http://www.w3.org/2000/
    const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
    save_link['href'] = urlObject.createObjectURL(export_blob);
    save_link['download'] = name;
    // 创建事件对象
    const ev = document.createEvent('MouseEvents');
    ev.initMouseEvent(
      'click',
      true, false, window, 0, 0, 0, 0, 0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    save_link.dispatchEvent(ev);
    document.body.removeChild(a);
  }

  /**
   * 获取当前地区时区
   */
  public static getTimeone() {
    // 时区默认为本地时区
    let displaySettings = {
      timeType: 'local'
    };
    if (localStorage.getItem('displaySettings') && localStorage.getItem('displaySettings') !== 'undefined') {
      displaySettings = JSON.parse(localStorage.getItem('displaySettings'));
      if (displaySettings.timeType === 'UTC') {
        return 'GMT+00:00';
      } else {
        const times = new Date();
        const arr = (times.toString()).split(' ');
        return arr[5];
      }
    } else {
      return 'GMT+00:00';
    }
  }

  /**
   * 获取秒数  （毫秒数转秒数）
   * param timestamps
   * returns {number}
   */
  public static getSeconds(timestamps) {
    const num = Number(timestamps) / 1000;
    return Math.round(num);
  }

  /**
   * 去前后空格
   */
  public static trim(str) {
    let str2;
    if (str === null) {
      str2 = null;
    } else {
      str2 = str.toString().replace(/(^\s*)|(\s*$)/g, '');
    }
    return str2;
  }

  public static formatSeconds(times) {
    let result = '00:00:00';
    let hour, minute, second;
    if (times > 0) {
      hour = Math.floor(times / 3600);
      if (hour < 10) {
        hour = '0' + hour;
      }
      minute = Math.floor((times - 3600 * hour) / 60);
      if (minute < 10) {
        minute = '0' + minute;
      }

      second = Math.floor((times - 3600 * hour - 60 * minute) % 60);
      if (second < 10) {
        second = '0' + second;
      }
      result = hour + ':' + minute + ':' + second;
    }
    return result;
  }

  public static formatTime(time: string) {
    let h = 0, seconds = 0, m = 0, s = 0, length = 0;
    if (time.indexOf(':') !== -1) {
      length = time.split(':').length - 1;
      if (length === 1) {
        m = Number(time.split(':')[0]);
        s = Number(time.split(':')[1]);
        seconds = m * 60 + s;
      } else if (length === 2) {
        h = Number(time.split(':')[0]);
        m = Number(time.split(':')[1]);
        s = Number(time.split(':')[2]);
        seconds = h * 3600 + m * 60 + s;
      }
    }
    return seconds;
  }

  public static setCookie(name, value, day) {
    if (day !== 0) {     // 当设置的时间等于0时，不设置expires属性，cookie在浏览器关闭后删除
      const expires = day * 24 * 60 * 60 * 1000;
      const date = new Date(+new Date() + expires);
      document.cookie = name + '=' + escape(value) + ';expires=' + date.toUTCString();
    } else {
      if (value && JSON.parse(value)._Total % JSON.parse(value)._pageSize === 1) {
        const data = {
          _Total: JSON.parse(value)._Total,
          _pageIndex: JSON.parse(value)._pageIndex - 1,
          _pageSize: JSON.parse(value)._pageSize
        };
        console.log(data);
        document.cookie = name + '=' + escape(JSON.stringify(data));
      } else {
        document.cookie = name + '=' + escape(value);
      }
    }
  }

  public static getCookie(name) {
    let c_start;
    let c_end;
    if (document.cookie.length > 0) {
      c_start = document.cookie.indexOf(name + '=');
      if (c_start !== -1) {
        c_start = c_start + name.length + 1;
        c_end = document.cookie.indexOf(';', c_start);
        if (c_end === -1) {
          c_end = document.cookie.length;
        }
        return unescape(document.cookie.substring(c_start, c_end));
      }
    }
    return '';
  }

  /**
   * 获取uploadUrl
   */
  public static getUploadUrl() {
    const ossConfig = SessionUtil.getOssConfig();
    return `https://${CommonAesService.decrypt(ossConfig.bucket)}.${CommonAesService.decrypt(ossConfig.region)}.aliyuncs.com/`;
  }
  /**
   * 详情页返回列表获取pageIndex
   */
  public static getPageIndex() {
    if (CommonUtil.getCookie('pageBean')) {
      return {pageIndex: JSON.parse(CommonUtil.getCookie('pageBean'))._pageIndex,
        pageSize: JSON.parse(CommonUtil.getCookie('pageBean'))._pageSize};
    } else {
      return {
        pageIndex: 1,
        pageSize: 10
      };
    }
  }
  /**
   * utc时间转 gmt时间
   */
  public static utcToGmt(date) {
    const timestamp = new Date(date).getTime();
    const newTime = new Date(timestamp);
    const year = newTime.getFullYear();
    const month = (newTime.getMonth() + 1) < 10 ? '0' + (newTime.getMonth() + 1) : (newTime.getMonth() + 1);
    const day = newTime.getDate() < 10 ? '0' + newTime.getDate() : newTime.getDate();
    const hours = newTime.getHours() < 10 ? '0' + newTime.getHours() : newTime.getHours();
    const minutes = newTime.getMinutes() < 10 ? '0' + newTime.getMinutes() : newTime.getMinutes();
    const seconds = newTime.getSeconds() < 10 ? '0' + newTime.getSeconds() : newTime.getSeconds();
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  /**
   * 数字格式化
   */
  public static toThousands(nums) {
    let num = (nums || 0).toString(), result = '';
    while (num.length > 3) {
      result = ',' + num.slice(-3) + result;
      num = num.slice(0, num.length - 3);
    }
    if (num) { result = num + result; }
    return result;
  }
  public static toStringThou(num) {
    if (num !== null && num !== undefined) {
      return  (num.toString().indexOf ('.') !== -1) ? num.toLocaleString() : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    }
  }
  public static getOrderState(nums) {
    switch (nums) {
      case 0:
        return '待付款';
      case 1:
        return '待发货';
      case 2:
        return '待收货';
      case 3:
        return '退款中';
      case 4:
        return '已完成';
      case 5:
        return '交易关闭';
      default:
        return '--';
    }
  }
}
