import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd, Params} from '@angular/router';

import {filter} from 'rxjs/operators';
import {NzI18nService} from 'ng-zorro-antd';

interface IBreadcrumb {
  label: string;
  params: Params;
  url: string;
}

const ADD = 'add';
const UPDATE = 'update';
const VIEW = 'view';
const ROUTE_DATA_BREADCRUMB = 'breadcrumb';

@Component({
  selector: 'app-rd-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: IBreadcrumb[] = [];
  language;
  constructor(private activatedRoute: ActivatedRoute,
              private i18n: NzI18nService,
              private router: Router) {
    this.language = i18n.getLocaleData('breadcrumb');
    this.breadcrumbs = [];
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      const root: ActivatedRoute = this.activatedRoute.root;
      this.breadcrumbs = this.getLastBreadcrumbs(root);
    });
  }

  ngOnInit() {
  }

  /**
   * 获取最后一个路由的数据
   * param {ActivatedRoute} route
   * returns {Data}
   */
  getLastBreadcrumbs(route: ActivatedRoute): IBreadcrumb[] {
    // 找到最后一个路由
    while (route.firstChild) {
      route = route.firstChild;
    }
    // 把路由里面的数据拷贝一份 防止改变源数据
    if (route.snapshot.data && route.snapshot.data.breadcrumb) {
      const breadcrumb = JSON.parse(JSON.stringify(route.snapshot.data.breadcrumb));
      this.translate(breadcrumb);
      if (route.snapshot.params.type) {
        const label = this.getTypeLabel(route.snapshot.params.type);
        breadcrumb[breadcrumb.length - 1].label = label + breadcrumb[breadcrumb.length - 1].label;
        return breadcrumb;
      } else {
        return breadcrumb as IBreadcrumb[];
      }
    } else {
      return [];
    }

  }

  private getTypeLabel(type): string {
    let title;
    switch (type) {
      case ADD:
        title = this.language.add;
        break;
      case UPDATE:
        title = this.language.update;
        break;
      case VIEW:
        title = this.language.view;
        break;
    }
    return title;
  }

  /**
   * 统一翻译面包屑
   * param breadcrumb
   */
  private translate(breadcrumb) {
    breadcrumb.forEach(item => {
      item.label = this.i18n.translate(`${ROUTE_DATA_BREADCRUMB}.${item.label}`);
    });
  }
}
