import axios from 'axios';
import {SessionUtil} from '../../shared-module/util/session-util';
import {CommonAesService} from '../../shared-module/util/common-aes.service';
// 请求默认带上cookie相关信息
axios.defaults.withCredentials = true;
// 请求默认超时时长
axios.defaults.timeout = 1200000;
// axios.defaults.headers.post['content-type'] = 'application/json';
axios.interceptors.request.use(
  conf => {
    conf.headers.token = SessionUtil.getToken();
    conf.headers.agreement = CommonAesService.encrypt(new Date().getTime());
    return conf;
  },
  error => {
    // 抛出请求错误信息
    return Promise.reject(error.response);
  }
);
/**
 * 响应拦截, 根据状态码做相应的处理
 */
axios.interceptors.response.use(
  response => {
    const {code} = response.data;
    if (code === 'S00A0002') {
      window.location.href = '/';
    } else {
      return response.data;
    }
  },
  error => {
    // 请求失败处理
    if (error.response) {
      switch (error.response.status) {
        case 404:
          break;
        case 401:
          console.log('请求失败处理1');
          break;
        case 500:
          console.log('请求失败处理2');
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
