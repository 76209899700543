export default {
  addClassify: '新增分类',
  updateClassify: '修改分类',
  resourceClassify: '资源分类',
  add: '新增',
  delete: '删除',
  okTest: '确定',
  cancel: '取消',
  clickUploadImage: '点击上传图片',
  clickUpdateImage:  '点击修改图片',
  areYouSureToDeleteThisResourceCategory: '确定删除该资源分类？',
  classificationDeletedWillNotBeRestoredPleaseBeCarefulOperation: '分类删除后，将不可恢复，请谨慎操作',
  classifyName: '分类名称',
  icon: '图标',
  belongsToTheCategory: '所属类别',
};

