import table from './table/table.en_US';
import form from './form/form.en_US';
import common from './common/common.en_US';
import breadcrumb from './breadcrumb/breadcrumb.en_US';
import notfound from './notfound/notfound.en_US';
import classify from './classify-management/classify.en_US';
import columnManage from './columnManage/columnManage.en_US';
import evaluate from './evaluate/evaluate.en_US';
import resourceManage from './resourceManage/resourceManage.en_US';
import systemManage from './systemManage/systemManage.en_US';

export default {
  table: table,
  form: form,
  common: common,
  breadcrumb: breadcrumb,
  notfound: notfound,
  classify: classify,
  columnManage: columnManage,
  evaluate: evaluate,
  resourceManage: resourceManage,
  systemManage: systemManage
};
