// import storage from 'localstorage-expires';
import * as _ from 'lodash';
import {CommonAesService} from './common-aes.service';
export class SessionUtil {
  /**
   * 获取用户信息
   */
  static getToken() {
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    if (loginInfo) {
      return loginInfo.token;
    }
  }

  /**
   * 获取百度相关配置
   */
  static getBaiduBceConfig() {
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    if (loginInfo && loginInfo.baiduBceConfig) {
      return {
        apiKey: CommonAesService.decrypt(loginInfo.baiduBceConfig.apiKey),
        secretKey: CommonAesService.decrypt(loginInfo.baiduBceConfig.secretKey)
      };
    } else {
      console.error('百度API配置获取失败！');
      return {
        apiKey: '',
        secretKey: ''
      };
    }
  }
  /**
   * 获取用户id
   */
  static getUserId() {
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    if (loginInfo) {
      return loginInfo.id;
    }
  }

  /**
   * 获取权限id
   */
  static getRoleId() {
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    if (loginInfo) {
      return loginInfo.roleId;
    }
  }

  static getPermission() {
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    if (loginInfo) {
      return loginInfo.operations;
    }
  }
  static getPermissionKey(code) {
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    if (loginInfo) {
      const codeList = loginInfo.operations;
      return _.includes(codeList, code);
    }
  }

  /**
   * 获取文件上传oss配置
   */
  static getOssConfig() {
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    if (loginInfo && loginInfo.ossConfig) {
      return loginInfo.ossConfig;
    } else {
      return {};
    }
  }
}
