export const COLUMN = `/ksr_manager/column`;


// Banner 页面的相关操作
export const QUERY_BANNER_IN_FO_LIST = `${COLUMN}/queryBannerInfoList`;
export const QUERY_BANNER_IN_FO_BY_ID = `${COLUMN}/queryBannerInfoById`;
export const ADD_BANNER_IN_FO = `${COLUMN}/addBannerInfo`;
export const ADD_BANNER_RESOURCE = `${COLUMN}/addBannerResource`;
export const MODIFY_BANNER_IN_FO = `${COLUMN}/modifyBannerInfo`;
export const DELETE_BANNER_IN_FO_BATCH = `${COLUMN}/deletebannerinfobatch`;
export const DELETE_BANNER_RESOURCE_BATCH = `${COLUMN}/deleteBannerResourceBatch`;
export const MODIFY_BANNER_STATE = `${COLUMN}/modifyBannerState`;
export const MODIFY_BANNER_SORT = `${COLUMN}/modifyBannerSort`;

// 栏目配置 页面的相关操作
export const QUERY_COLUMN_IN_FO_LIST = `${COLUMN}/queryColumnInfoList`;
export const MODIFY_COLUMN_STATE = `${COLUMN}/modifyColumnState`;
export const ADD_COLUMN_IN_FO =  `${COLUMN}/addColumnInfo`;
export const DELETE_COLUMN_IN_FO = `${COLUMN}/deleteColumnInfo`;
export const MODIFY_COLUMN_IN_FO_INIT  = `${COLUMN}/modifyColumnInfoInit`;
export const MODIFY_COLUMN_IN_FO = `${COLUMN}/modifyColumnInfo`;
export const MODIFY_COLUMN_INDEX = `${COLUMN}/modifyColumnIndex`;
