export default {
  add: '新增',
  update: '编辑',
  delete: '删除',
  moveUp: '上移',
  moveDown: '下移',
  cancel: '取消',
  okTest: '确定',
  addBanner: '新增Banner',
  updateBanner: '修改Banner',
  areYouSureToDeleteTheBanner: '确定删除该Banner？',
  afterDeletionItWillNotBeRecoverablePleaseBeCarefulBanner: 'Banner删除后，将不可恢复，请谨慎操作',
  yes: '是',
  no: '否',
  addition: '添加',
  remove: '移除',
  SureToRemoveResources: '确定移除资源？',
  SureToRemoveResourcesAfterRemovalItCannotBeRestoredPleaseBeCareful: '移除后，将不可恢复，请谨慎操作',
  addColumn: '新增栏目',
  updateColumn: '修改栏目',
  areYouSureToDeleteThisColumn: '确定删除该栏目？',
  columnDeletedWillNotBeRestoredPleaseBeCarefulOperation: '栏目删除后，将不可恢复，请谨慎操作',
  operation: '操作',
  author: '作者',
  resourceType: '资源类型',
  isNoShelf: '是否上架',
  showStyle: '显示样式',
  isNoHot: '是否热门',
  isNoRecommend: '是否推荐',
  columnName: '栏目名称',
  isNoShow: '是否显示',
  resourceCover: '资源封面',
  resourceName: '资源名称',
  graphicList: '图文列表',
  transverseShuffling: '横向轮播',
  BannerMap: 'Banner缩略图',
  theTheme: '答案主题',
  answerType: '答案类型',
  associatedResources: '关联资源',
  initiator: '发起人',
  activityTime: '活动时间',
  numberOfParticipants: '参与人数',
  readingScores: '阅卷评分',
  userName: '用户名',
  grade: '评分',
  questionNumber: '题号',
  theme: '主题',
  timeToMarket: '投放时间',
  addTopic: '添加题目',
  topic: '题目',
  referenceVersion: '参考答案',
  classification: '分级',
  topicType: '题目类型',
  issueImg: '问题图片',
  contentOfFeedback: '意见反馈内容',
  cellPhoneNumber: '手机号码',
  feedbackTime: '反馈时间',
  isNoDispose: '是否处理',
  batchShow: '批量显示',
  areYouSureToDeleteTheComment: '确定删除该评论？',
  commentsDeletedWillNotBRPBC: '评论删除后，将不可恢复，请谨慎操作',
  columnThumbnail: '栏目缩略图',
  firstClass: '开学第一课',
  workTogetherToWinReadingPoints: '一起答题赢阅读积分',
  returnList: '返回列表',
  didNotScore: '未评分',
  hasScore: '已评分',
  submitMyGrade: '提交我的评分',
  score: '分数(分)',
  openComments:  '是否开放评论',
  serialNumber: '序号',
  objectiveItem: '客观题',
  newActivityToAnswerQuestions: '新增答题活动',
  updateActivityToAnswerQuestions: '修改答题活动',
  issue: '发布',
  startTime: '开始时间',
  endTime: '结束时间',
  subjectiveItem: '主观题',
  templateType: '模板类型',
  urlAddress: 'URL地址',
};
