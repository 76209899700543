import {AfterViewInit, Directive, ElementRef, HostListener, Input} from '@angular/core';
import {ModalService} from '../service/modal/modal.service';
import {NzI18nService} from 'ng-zorro-antd';
import {SessionUtil} from '../util/session-util';

@Directive({
  selector: '[appAccessPermission]'
})
export class AccessPermissionDirective implements AfterViewInit {

  @Input() appAccessPermission;
  // 用户权限列表
  role = [];
  // 国际化
  language: any;

  constructor(private el: ElementRef,
              private $i18n: NzI18nService,
              private $message: ModalService) {
    // 从用户信息里面获取权限列表
    this.role = SessionUtil.getPermission();
    this.language = $i18n.getLocale();
  }

  /**
   * 监听点击事件 权限判断防止页面改 disabled
   * param event
   */
  @HostListener('click', ['$event'])
  onClick(event) {
    if (!this.hasPermission()) {
      this.$message.warning(this.language.common.permissionMsg);
      throw new Error('您没有操作权限!');
    }
  }

  ngAfterViewInit(): void {
    if (!this.hasPermission()) {
      this.el.nativeElement.disabled = 'disabled';
    }
  }

  /**
   * 判断用户是否有该操作
   */
  private hasPermission() {
    if (this.appAccessPermission) {
      if (this.role.includes(this.appAccessPermission)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }

  }
}
