import { Pipe, PipeTransform } from '@angular/core';

/**
 * 时间转化 管道
 */
@Pipe({name: 'timeConversion'})
export class TimeConversionPipe implements PipeTransform {
  transform(value: number, exponent?: number): number {
    return this.formatTime(value, exponent);
  }

  /**
   *  dateNum 为时间戳  type 根据自己的需求填写
   */
  formatTime(dateNum: number, type: number) {
    /*
      1 2017/04/19 18:00:00
      2 2017年04月19日 18:00:00
      3 2017/04/19 18时
      4 2017/04/19
      5 2017-04-19 18:10:10
      6 2017-04-19 18:10:10 星期三
      7 2017-04-19T18:10
      8 2017-04-19 18:10:10 (星期三)
    */
    const date = new Date(dateNum);

    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    if (month.length === 1) {
      month = 0 + month;
    }
    let day = date.getDate().toString();
    if (day.length === 1) {
      day = 0 + day;
    }
    // let hour = date.getHours();
    let hour = date.getHours().toString();
    if (hour.length === 1) {
      hour = 0 + hour;
    }
    let minutes = date.getMinutes().toString();
    if (minutes.length === 1) {
      minutes = 0 + minutes;
    }
    let seconds = date.getSeconds().toString();
    if (seconds.length === 1) {
      seconds = 0 + seconds;
    }
    const week = {
      0: '星期天',
      1: '星期一',
      2: '星期二',
      3: '星期三',
      4: '星期四',
      5: '星期五',
      6: '星期六',
    };
    const todayWeek = week[date.getDay()];

    const timeMap = {
      1: `${year}/${month}/${day} ${hour}:${minutes}:${seconds}`,
      2: `${year}年${month}月${day}日 ${hour}:${minutes}:${seconds}`,
      3: `${year}-${month}-${day} ${hour}时`,
      4: `${year}/${month}/${day}`,
      5: `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`,
      6: `${year}-${month}-${day} ${hour}:${minutes}:${seconds} ${todayWeek}`,
      7: `${year}-${month}-${day}T${hour}:${minutes}`,
      8: `${year}-${month}-${day} ${hour}:${minutes}:${seconds} (${todayWeek})`,
    };
    return timeMap[type];
  }
}
