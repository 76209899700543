export default {
  'add': '新增',
  'update': '修改',
  'view': '查看',
  'business': '业务',
  'columns': '运营管理',
  'columnList': '栏目列表',
  'column': '栏目',
  'resources': '资源管理',
  'home': '首页',
  'resourceList': '有声书管理',
  'resource': '有声书',
  'RefundOrderParticulars': '退款订单详情',
  'OrderParticulars': '订单详情',
  'resourceParticulars': '有声书详情',
  'PhysicalOrderList': '实体书订单管理',
  'evaluates': '测评管理',
  'PreferentialActivityParticulars': '优惠活动详情',
  'eCommerceManage': '电商管理',
  'integralExchange': '兑换',
  'preferentialCoupon': '优惠活动',
  'preferentialActivityList': '优惠活动列表',
  'commodityParticulars': '商品详情',
  'commodityList': '商品管理',
  'VirtualOrderList': '电子类订单管理',
  'VirtualOrderParticulars': '电子类订单详情',
  'integralExchangeDetail': '积分兑换明细',
  'integralExchangeList': '积分兑换列表',
  'commodity': '商品',
  'vipTypeManagement': 'VIP会员类型管理',
  'couponManagement': '优惠券列表',
  'vipMemberManagement': 'VIP会员管理',
  'coupon': '优惠券',
  'vipType': 'VIP类型',
  'rewardActivityList': '奖励活动',
  'winnerList': '中奖名单',
  'rewardActivity': '新增奖励活动',
  'userStatistics': '使用者统计分析',
  'readStatistics': '阅读统计分析',
  'bookStatistics': '书籍统计分析',
  'lotteryList': '抽奖列表',
  'lotteryActivity': '抽奖活动',
  'lotteryParticulars': '抽奖活动详情',
  'activity': '活动管理',
  'activityList': '活动列表',
  'evaluateList': '单本测评列表',
  'rewardBookList': '赠书活动列表',
  'evaluate': '测评',
  'classifyManagement': '分级分类',
  'classifyManagementList': '分类管理',
  'gradingManagement': '分级管理',
  'gradingManagementList': '分级列表',
  'users': '用户管理',
  'userList': '用户列表',
  'user': '用户',
  'eBookResourceList': '电子书管理',
  'eBookResource': '电子书',
  'eBookResourceParticulars': '电子书详情',
  'bannerManage': 'Banner管理',
  'columnConfiguration': '栏目管理',
  'commentManagement': '评论管理',
  'mysteriousAnswer': '神秘答题',
  'userInfo': '用户详情',
  'roles': '角色管理',
  'roleList': '角色列表',
  'qrManager': '二维码管理',
  'opinionFeed': '意见反馈管理',
  'videoResourceList': '微课视频管理',
  'videoResource': '微课视频',
  'videoResourceParticulars': '微课视频详情',
  'helpReadResourceParticulars': '导读手册管理',
   'pictureBookList': '绘本管理',
  'helpReadResourceDetail': '导读手册',
  'pictureBook': '绘本',
  'pictureBookParticulars': '绘本详情',
  'system': '系统管理',
  'systemLog': '系统日志管理',
  'operationLog': '操作日志管理',
  'diversionStatistics': '导流统计',
  'firstLessonStatistics': '作家视频统计分析',
  'moduleUsageStatistics': '各模块使用统计',
  'bookReviewList': '书评统计',
  'bookReviewDetail': '评论列表',
  'memberList': '会员管理',
  'vip': '会员管理',
  'appraisalList': '测评统计',
  'evaluateInformation': '题库管理',
  'information': '题目',
  'thematic': '专题',
  'thematicManage': '专题管理',
  'aloneEvaluate': '单本测评',
  'statistics': '统计分析',
  'questionDetail': '题目详情',
  'firstClass': '开学第一课',
  'columnGroup': '栏目组管理',
  'resourceGroupManage': '资源分组管理',
  'resourceGroup': '资源分组',
  'liveManage': '直播间管理',
  'resourceGroupView': '资源分组详情',
  'authorManage': '作家专栏',
  'authorList': '作家列表',
  'authorDetail': '作家',
  'authorView': '作家详情',
  'articleList': '专栏文章',
  'authorInfo': '个人专栏',
  'applyManage': '报名管理',
  'keywordManage': '热门搜索关键词',
  'synthesizeEvaluates': '综合测评',
  'synthesizeEvaluatesDetail': '测评得分列表',
  'epidemicSituationManage': '疫情管理'
};
